import * as React from "react";
import Layout from "../components/layout";


const Index = ({ data }) => {
  return (
    <Layout pageTitle="KSEA NBM">
      <h1 className="text-2xl font-bold pb-6">Sorry. We couldn't find what you're looking for. 😔 </h1>

      <div className="my-10">
        &nbsp;
      </div>
    </Layout>
  );
};


export default Index;
